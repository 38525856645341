import {TTranslation} from '@/types/';

export const FallbackTranslation: TTranslation = {
    'ru': {
        'errors_try_later': 'Попробуйте позже',
        'errors_connection_error': 'Ошибка соединения',
        'common_refresh': 'Обновить',
    },
    'en': {
        'errors_try_later': 'Try later',
        'errors_connection_error': 'Connection error',
        'common_refresh': 'Update',
    },
    'es': {
        'errors_try_later': 'Intenta más tarde',
        'errors_connection_error': 'Error de conexión',
        'common_refresh': 'Actualizar',
    },
};
